

import { defineComponent } from 'vue'
import BookingNavBar from "@/components/BookingNavBar.vue";

export default defineComponent({
    name: 'BookingView',
    components: {
        BookingNavBar
    },
    data() {
        return {
            loaded: true
        }
    },
    beforeUnmount() {
        let element = document.getElementById("justhire-iframe")
        if (element) {
            element.remove()
            console.log("removing")
        }
    },
    mounted() {
        let recaptchaScript = document.createElement('script')
        recaptchaScript.setAttribute('src', 'http://localhost:8000/js/widget.js')
        recaptchaScript.setAttribute('id', 'justhire-iframe')
        document.body.appendChild(recaptchaScript)
    }
})


import { defineComponent } from 'vue'
import HomeHero from '@/components/HomeHero.vue'
import KeyUSPs from '@/components/KeyUSPs.vue'
import ContactUsPanel from '@/components/ContactUsPanel.vue'
import Footer from "@/components/Footer.vue"

export default defineComponent({
  name: 'HomeView',
  components: {
    HomeHero,
    KeyUSPs,
    ContactUsPanel,
    Footer
  }
})

<template>
    <div class="bg-gray-50">
        <div class="mx-auto max-w-7xl py-24 sm:px-2 sm:py-32 lg:px-4">
            <div class="mx-auto grid max-w-2xl grid-cols-1 gap-y-10 gap-x-8 px-4 lg:max-w-none lg:grid-cols-3">
                <div v-for="incentive in incentivesOne" :key="incentive.name" class="text-center sm:flex sm:text-left lg:block lg:text-center">
                    <div class="sm:flex-shrink-0">
                        <div class="flow-root">
                            <component :is="incentive.icon" class="mx-auto h-12">Hello</component>
                        </div>
                    </div>
                    <div class="mt-3 sm:mt-0 sm:ml-3 lg:mt-3 lg:ml-0">
                        <h3 class="text-sm font-medium text-gray-900">{{ incentive.name }}</h3>
                        <p class="mt-2 text-sm text-gray-500">{{ incentive.description }}</p>
                    </div>
                </div>
                <div v-for="incentive in incentivesTwo" :key="incentive.name" class="text-center sm:flex sm:text-left lg:block lg:text-center mt-20">
                    <div class="sm:flex-shrink-0">
                        <div class="flow-root">
                            <component :is="incentive.icon" class="mx-auto h-12">Hello</component>
                        </div>
                    </div>
                    <div class="mt-3 sm:mt-0 sm:ml-3 lg:mt-3 lg:ml-0">
                        <h3 class="text-sm font-medium text-gray-900">{{ incentive.name }}</h3>
                        <p class="mt-2 text-sm text-gray-500">{{ incentive.description }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { CurrencyPoundIcon, BanknotesIcon, InboxIcon, CreditCardIcon, Cog6ToothIcon, CodeBracketSquareIcon } from '@heroicons/vue/24/solid'

import {defineComponent} from "vue";

export default defineComponent({
    name: 'KeyUSPs',
    components: { CurrencyPoundIcon, BanknotesIcon, InboxIcon, CreditCardIcon, Cog6ToothIcon, CodeBracketSquareIcon },
    data() {
        return {
            incentivesOne: [
                {
                    name: 'No Up Front Fee',
                    description: "No monthly payments or Setup Fee, only pay a small fee when people book through the software, only pay when you get paid ",
                    icon: 'CurrencyPoundIcon'
                },
                {
                    name: 'Make More Sales',
                    description: 'Reach customers outside of your office hours and at the weekends. Giving your business more sales and giving you a step up from your competitors.',
                    icon: 'BanknotesIcon'
                },
                {
                    name: 'Automated Customer Communication',
                    description: "Our software will email automatically to confirm their order, pre-delivery email this can include what not to put in the skip but also as a reminder that the skip lorry will need space to deliver the skip and to leave space for the skip itself.",
                    icon: 'InboxIcon'
                },
            ],
            incentivesTwo: [
                {
                    name: 'Earn with referrals',
                    description: "If a customer tries to book through the software on your website but you do not service their area, we will place that booking with another company that does and we will pay you a referral fee.",
                    icon: 'CreditCardIcon',
                },
                {
                    name: 'Select Your Pricing',
                    description: 'choose the pricing of your products and change them as you wish. You can even change the pricing dependent on location.',
                    icon: 'Cog6ToothIcon',
                },
                {
                    name: 'Easy Integration',
                    description: "Our software can be easily integrated into your existing site. We can integrate the booking platform into your existing website fitting the branding seamlessly with 1 line of code.",
                    icon: 'CodeBracketSquareIcon',
                },
            ]
        }
    }
})
</script>
